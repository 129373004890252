import React, { useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import t from '../services/GetTranslationValue'
import ThemeContext from "../context/ThemeContext"

const NotFoundPage = () => {

  const {translations}= useContext(ThemeContext);

  return <Layout>
    <SEO title={t.get(translations, '404-seoTitle')}/>
    <div className="not-found">
      <p>{t.get(translations, '404-text')}
      </p>
    </div>
  </Layout>
}

export default NotFoundPage
